import { useEffect } from "react";
import { useRouter } from "next/router";
import Meta from '/components/Meta'

const Home = () => {
  const router = useRouter()
  useEffect(() => { router.push('/calendar') }, [router])
  return <Meta />
}

export default Home